import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'

import { Container, Html, ItemHeading, Layout, ResponsiveImage, Section, SEO } from '../components'
import { contentColorMap, desktopBreakpoint, makeContentStyle, textColumnStyle } from '../styles'
import { Site, ThemeColorName } from '../types'

interface ContentTemplateProps {
  location: Location
  readonly data: {
    readonly markdownRemark: any
    readonly site: Site
  }
}

const style = {
  imageWrapper: css`
    @media ${desktopBreakpoint} {
      max-width: 50vw;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  content: css`
    @media ${desktopBreakpoint} {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  `,
  contentCol: css`
    z-index: 9;
    position: relative;
    @media ${desktopBreakpoint} {
      width: 50%;
    }
  `,
  contentInner: css`
    margin-top: 4em;
  `,
  article: css`
    display: flex;
    flex-direction: column;
  `
}

const SingleTemplate = ({ data, location: { pathname } }: ContentTemplateProps) => {
  const {
    markdownRemark: {
      frontmatter: { title, image, roles },
      html,
      fields: { type }
    }
  } = data
  const color = contentColorMap[type as keyof typeof contentColorMap] as ThemeColorName
  return (
    <Layout currPath={pathname} pageColor={color}>
      <SEO title={title.join(' ')} />
      <Section index={0} color={color} renderBg={true} headerOffset={true}>
        <article css={[makeContentStyle(color), style.article]}>
          {image && (
            <Container>
              <div css={style.imageWrapper}>
                <Parallax y={[-50, 50]}>
                  <ResponsiveImage alt={title.join(' ')} image={image} />
                </Parallax>
              </div>
            </Container>
          )}
          <Container>
            <div css={style.content}>
              <div css={style.contentCol}>
                <ItemHeading title={title} titleSuffix={roles} />
                <Html css={[textColumnStyle, style.contentInner]}>{html}</Html>
              </div>
            </div>
          </Container>
        </article>
      </Section>
    </Layout>
  )
}

export default SingleTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        type
      }
      frontmatter {
        date(formatString: "D.M.YYYY")
        title
        roles
        image {
          mobile: childImageSharp {
            fluid(maxWidth: 1000) {
              presentationWidth
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
